import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import backBtn from '../../assets/icons/back.png';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { createInspireActivity, getLocationFromActivity } from '../../redux/slices/mobile/activitySlice';
import FunctionalButton from '../FunctionalButton/FunctionalButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { getJourneyNavOption } from '../../utils/get-nav-option';
import ReportItem from '../SharedComponents/ReportItem';
import JrnyCreate from '../jrnyCreate/jrnyCreate';
import { YesNo } from '../utils/subjects';
import ShareModal from './ShareModal';

const FeedOptionModal = ({
  data,
  isTimeline,
  showOptionModal,
  showOptionModalHandle
}) => {
  const { auth } = useSelector(authSelector);
  const [showAddInspirer, setShowAddInspirer] = useState(false);
  const [locations, setLocations] = useState([])
  const navigate = useNavigate();
  const location = useLocation();
  const [shareModal, setShareModal] = useState(false);
  let [userArr, setUserArr] = useState([]);
  let [placeArr, setPlaceArr] = useState([]);
  let [postValue, setPostValue] = useState({
    vissible: true,
    value: undefined,
  });

  let [jrnyModal, setJrnyModal] = useState(false);
  let jrnyModalControl = () => {
    if (jrnyModal) {
      navigate(-1);
    } else {
      window.history.pushState(null, "", location.pathname);
    }
    setJrnyModal((state) => !state);
  };


  const [isReportModal, setIsReportModal] = useState();
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });
  const dispatch = useDispatch();

  const isReportModalHandle = (action) => {
    setIsReportModal(action);
  };



  const showAddInspirerHandle = () => {
    setPostValue({ ...postValue, value: data.activity.name })
    dispatch(
      getLocationFromActivity({
        data: {
          sk: data.activity.sk,
          isEditable: YesNo.No,
        },
        callback: res => {
          console.log('getLocations:', res);
          setPlaceArr(res.locations);
        },
        id: data.activity.id,
        token: auth.token,
      }),
    );
    setShowAddInspirer((prevState) => !prevState);
  };

  const addIntoInspirerList = () => {
    setActionStates({ ...actionStates, loading: true });
    dispatch(
      createInspireActivity({
        data: {
          // inspireId: item.id
          sk: data.sk,
          createdAt: data.createdAt,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        token: auth.token,
        activityId: data.id,
      })
    ).then((res) => {
      console.log('ResponseInspirerJOurney', res);
      if (res.meta.requestStatus == 'fulfilled') {
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        navigate(getJourneyNavOption(res?.payload));
      }
      if (res.meta.requestStatus == 'rejected') {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };

  const shareModalHandle = () => {
    setShareModal(prevState => !prevState)
  }

  console.log("InspiredData", data)
  return (
    <Popup
      open={showOptionModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='relative space-y-3 bg-white w-[450px] rounded-[20px] p-10  max-[767px]:p-5 max-[767px]:h-screen  max-[767px]:overflow-y-scroll max-[600px]:rounded-none'>
        <div className='flex gap-3 '>
          <button
            onClick={() =>
              showAddInspirer
                ? showAddInspirerHandle()
                : showOptionModalHandle(false)
            }
          >
            <img
              src={showAddInspirer ? backBtn : Cross}
              alt='back button'
              className='w-[15px] h-[25px]'
            />
          </button>

          <p className='text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary'>
            Feed Options
          </p>
        </div>
        <div className='space-y-3'>
          {!isTimeline && (<div>
            {!showAddInspirer && data?.user?.id != auth?.user?.id && (
              <button
                onClick={showAddInspirerHandle}
                className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
              >
                Get Inspired
              </button>
            )}
          </div>)}
          <div>
            {!showAddInspirer && data?.user?.id != auth?.user?.id && (
              <button
                onClick={() => isReportModalHandle(true)}
                className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
              >
                Report
              </button>
            )}
          </div>
          <div>
            <button
              onClick={shareModalHandle}
              className='bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro'
            >
              Share
            </button>
          </div>
        </div>
        <ReportItem
          data={data}
          isModal={isReportModal}
          controlFunction={isReportModalHandle}
        />
        {showAddInspirer && (
          <div className='space-y-3'>
            <p>
              This will clone the journey and will be added to your journey list
            </p>
            {/* <p>
              you took this journey for a reason. you can Archive and come back
              to it later.
            </p> */}
            <div className='space-x-3 flex items-center gap-3'>
              {/* <button
                onClick={addIntoInspirerList}
                className={`${'bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor'} border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro`}
              >
                Add
              </button> */}
              {/* <FunctionalButton
                actionStates={actionStates}
                callback={() => addIntoInspirerList()}
                actionStatesTexts={{
                  default: 'Start Journey',
                  loading: ' Starting Journey',
                  fulfilled: 'Journey created Successfully',
                  rejected: 'Journey creation Failed',
                }}
                padding={'5px_10px'}
                text={{ style: 'italic', size: '12px' }}
              /> */}
              <button
                onClick={() => setJrnyModal(true)}
                className={`${'bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor'} border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro`}
              >
                Start Journey
              </button>
              <button
                onClick={showAddInspirerHandle}
                className={`${'bg-jrnyfy-themeColor text-white border-jrnyfy-themeColor'} border-2 p-[3px_10px_3px_10px] text-[12px] italic rounded-[50px] hover:scale-[1.1]  font-SourceSansPro`}
              >
                back
              </button>
            </div>
          </div>
        )}
        {shareModal && (
          <ShareModal
            name={data.activity.name}
            photo={data.activity.thumbnail}
            JourneyId={data.activity.id}
            JourneySk={data.activity.sk}
            JourneyCreatedAt={data.activity.createdAt}
            shareModalHandle={shareModalHandle}
            shareModal={shareModal}
          />
        )}
        {jrnyModal && (
          <JrnyCreate
            setPostValue={setPostValue}
            postValue={postValue}
            setPlaceArr={setPlaceArr}
            placeArr={placeArr}
            setUserArr={setUserArr}
            userArr={userArr}
            jrnyModal={jrnyModal}
            jrnyModalControl={jrnyModalControl}
          />
        )}
      </div>
    </Popup>
  );
};

export default FeedOptionModal;
