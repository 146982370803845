import { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import Cross from '../../assets/images/xmark-solid.svg';
import {
  activitySelector,
  addSuggestedTask,
  createTaskForActivity,
} from '../../redux/slices/mobile/activitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/mobile/authSlice';
import { onTypeChange } from '../utils/typing-change';
import {
  getSuggestedTasks,
  taskSelector,
} from '../../redux/slices/mobile/taskSlice';
import { buildUrlToSize } from '../../utils/build-image-url';
import defaultSvgTasks from '../../assets/images/defaultSvgTasks.svg';
import { PhotoSize, SuggestedTaskType, TaskType, ViewGroupType } from '../utils/subjects';
import FunctionalButton from '../FunctionalButton/FunctionalButton';

import SuggestedTask from './SuggestedTask';

const AddMilestone = ({
  setDataLoading,
  modalState,
  modalControl,
  setTimelineContent,
  timeLineContent,
  showFloatingMenuHandle,
}) => {
  let [postValue, setPostValue] = useState({
    vissible: true,
    value: undefined,
  });
  const { auth } = useSelector(authSelector);
  const { activity } = useSelector(activitySelector);
  const { suggestedTasks } = useSelector(taskSelector);
  const [isLoading, setIsLoading] = useState(false)
  const [suggestedTaskList, setSuggestedTaskList] = useState(suggestedTasks);
  const dispatch = useDispatch();
  const inputRef = useRef();
  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: '',
    typingTimeout: 0,
  });
  const [currPage, setCurrPage] = useState(0);
  const [contents, setContents] = useState([]);
  const [searchText, setSearchText] = useState(undefined);
  const [gptNameList, setGptNameList] = useState([]);

  let tags = [];
  if (activity.tags && activity.tags.length > 0) {
    tags = activity.tags.map((tag) => tag.name);
  }
  let existingTasksId = undefined;
  if (activity.tasks && activity.tasks.length > 0) {
    const f = activity.tasks.filter((t) => t?.suggestedTaskId != undefined);
    existingTasksId = f.map((t) => t?.suggestedTaskId);
  }

  const getSuggestedTasksList = (page, value) => {
    setIsLoading(true)
    const ts = [];
    if (activity.tags) {
      for (let i = 0; i < activity.tags.length; i++) {
        ts.push(activity.tags[i].name);
        if (
          activity.tags[i].attrs != undefined &&
          activity.tags[i].attrs.length > 0
        ) {
          const at = activity.tags[i].attrs;
          if (at != undefined) {
            for (let j = 0; j < at.length; j++) {
              ts.push(at[i]);
            }
          }
        }
      }
    }
    dispatch(
      getSuggestedTasks({
        data: {
          tags: ts.toString(),
          gpt_names: gptNameList,
          size: 10,
          from: 0,
          name: activity.name,
          activityId: activity.id,
          value: value,
        },
        callback: res => {
          // setGptFrom(res.gpt_from);
          console.log('suggestedTask===================', res);
          // setCurretnPage(prevState => prevState + page);
          //setIsLoading(false);
          if (res.length < 10) {
            //setIsEnd(true);
          }
          let gpt_names = [];
          res.forEach(item => {
            if (item.type == SuggestedTaskType.ChatGpt) {
              gpt_names.push(item.name);
            }
          });
          setGptNameList([...gptNameList, ...gpt_names]);
          if (page == 0) {
            setSuggestedTaskList(res);
          } else {
            setSuggestedTaskList(prevState => [...prevState, ...res]);
          }
          setIsLoading(false)
        },
        token: auth.token,
      }),
    );
  }

  useEffect(() => {
    getSuggestedTasksList(0, '')
    // dispatch(
    //   getSuggestedTasks({
    //     data: {
    //       tags,
    //       size: 100,
    //       from: 0,
    //       existingTasksId,
    //       activityId: activity.id,
    //       value: searchText,
    //     },
    //     token: auth.token,
    //   })
    // );
  }, [searchText]);

  return (
    <Popup
      open={modalState}
      closeOnDocumentClick={false}
      contentStyle={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <div className='relative bg-white w-[500px] rounded-[20px] p-[40px] max-[767px]:p-5 max-[767px]:h-full  max-[767px]:overflow-y-scroll max-[600px]:rounded-none '>
        {/* -------cross button-------- */}
        {/* <img
          src={Cross}
          alt='cross'
          className='cursor-pointer w-[25px] absolute top-[20px] left-[25px]'
          onClick={modalControl}
        /> */}

        <div className='flex items-start gap-3'>
          <img
            src={Cross}
            alt='cross'
            className='cursor-pointer w-[20px] '
            onClick={() => modalControl(false)}
          />

          <p className='font-semibold text-jrnyfy-textprimary w-full text-center'>
            Add Milestone
          </p>
        </div>

        {/* ------- main body start---- */}
        <div className='space-y-6'>
          <div className='flex flex-col mt-[20px] relative text-center'>
            <input
              type='text'
              ref={inputRef}
              value={typeInput.text}
              onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                setCurrPage(0);
                setContents([]);
                setSearchText(value);
              })}
              spellCheck='true'
              className='border-b-2 border-solid border-jrnyfy-textprimary min-h-[100px] max-[500px]:min-h-[70px] z-40 break-words text-[20px] max-[500px]:text-[14px] focus:outline-none'
            />
            {console.log('Text Length', typeInput)}
            <span
              className='absolute top-0 pointer-events-none w-full text-[20px] min-[300px]:max-[500px]:text-[14px] min-[350px]:max-[460px]:leading-[21px] text-jrnyfy-textprimary font-Montserrat z-[999]'
              style={
                typeInput.text == ''
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              add a milestone..
              <br />a milestone is big step you will take in your journey
            </span>
          </div>
          <div className='text-center'>
            <button
              onClick={() => {
                modalControl();
                // setDataLoading(true);
                const newTask = {
                  loading: true,
                  type: 1,
                  user: auth?.user,
                  task: { description: searchText },
                  activity: { id: activity.id, sk: activity.sk, createdAt: activity.createdAt, name: activity.name }
                };
                let tempTimelineContent = [newTask, ...timeLineContent];
                setTimelineContent([newTask, ...timeLineContent]);
                if (showFloatingMenuHandle) {
                  showFloatingMenuHandle(false);
                }
                dispatch(
                  createTaskForActivity({
                    data: {
                      text: searchText,
                      sk: activity.sk,
                      createdAt: activity.createdAt,
                      type: TaskType.Created,
                      groupType: ViewGroupType.TaskCustom
                    },
                    token: auth.token,
                    id: activity.id,
                    user: auth.user,
                    callback: (res) => {
                      console.log('CreateTaskForActivity', res);
                      // setDataLoading(false);
                      tempTimelineContent[0] = res?.results?.timeline;
                      setTimelineContent(tempTimelineContent);
                    },
                  })
                );
              }}
              className='bg-jrnyfy-themeColor p-[10px_20px] rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro italic'
            >
              add milestone
            </button>
          </div>
          <p className='text-jrnyfy-color2'>here are some suggestion</p>
          {isLoading ? (<div >
            <div className='flex flex-col justify-center items-center'>
              <div className="spinner"></div>
              <p>Please Wait...</p>
            </div>
          </div>) : (<div className='max-h-[300px] overflow-y-scroll pr-3'>
            {suggestedTaskList &&
              suggestedTaskList.map((item) => {
                return (
                  <SuggestedTask
                    modalControl={modalControl}
                    timeLineContent={timeLineContent}
                    setTimelineContent={setTimelineContent}
                    showFloatingMenuHandle={showFloatingMenuHandle}
                    item={item}
                    activity={activity}
                  />
                );
              })}
          </div>)}

          <div className='flex items-center '></div>
        </div>
      </div>
    </Popup>
  );
};

export default AddMilestone;
