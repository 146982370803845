import React, { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import FunctionalButton from "../FunctionalButton/FunctionalButton";
import Cross from "../../assets/images/xmark-solid.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  activitySelector,
  backgroundPhotoUploadQueue,
  removeTimelineItem,
  updateActivityTask,
} from "../../redux/slices/mobile/activitySlice";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";
import backBtn from "../../assets/icons/back.png";
import { ActionType, BackgroundUploadApiType, TaskStatus, UserFilter } from "../utils/subjects";
import UserListPopup from "../userListPopup/UserListPopup";
import DateTimePicker from "react-datetime-picker";
import AddProgress from "../jrnyTimline/addProgress";

const TimelineOptionsModal = ({
  index,
  data,
  deleteModal,
  deleteModalHandle,
  isProgressUpdate,
  isTask
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const { activity } = useSelector(activitySelector);
  const [milestoneUpdateLoading, setMilestoneUpdateLoading] = useState(false);
  const [dueDate, setDueDate] = useState(new Date())
  const { auth } = useSelector(authSelector);
  const [dataLoading, setDataLoading] = useState(false);
  let [progressPicModal, setProgressPicModal] = useState(false);
  const [screens, setScreens] = useState({
    menuScreen: true,
    editScreen: 0,
    dueDateScreen: 0
  })
  const [userPopupStatus, setUserPopupStatus] = useState(false);
  const dispatch = useDispatch();
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const [updatedMilestone, setUpdatedMilestone] = useState({
    text: data.text,
    desc: data?.task?.description ? data?.task?.description : data.task?.location?.description,
  });

  useEffect(() => {
    setUpdatedMilestone({
      text: data.text,
      desc: data?.task?.description ? data?.task?.description : data.task?.location?.description,
    });
  }, [data]);

  const progressPicModalHandle = (action) => {
    setProgressPicModal(action)
  }

  const showDeleteHandle = (action) => {
    console.log('fdfdfldfld')
    setShowDelete(action);
  };

  console.log("datadfdfdfd", data)

  const { timeLineContent, setTimelineContent } =
    useContext(timelineDataContext);
  let tempTimelineContent = [...timeLineContent];


  const removeTimelineItemHandle = () => {
    setActionStates({ ...actionStates, loading: true });
    tempTimelineContent.splice(index, 1);
    setTimelineContent(tempTimelineContent);
    dispatch(
      removeTimelineItem({
        data: { sk: data?.sk, createdAt: data?.createdAt, id: data?.id },
        id: activity?.id,
        token: auth?.token,
      })
    ).then((res) => {
      console.log("Response", res.meta.requestStatus);
      if (res.meta.requestStatus == "fulfilled") {
        setActionStates({ ...actionStates, loading: false, fulfilled: true });
        deleteModalHandle(false);
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
      if (res.meta.requestStatus == "rejected") {
        setActionStates({ ...actionStates, loading: false, rejected: true });
        deleteModalHandle(false);
        setTimeout(() => {
          setActionStates({
            ...actionStates,
            loading: false,
            fulfilled: false,
            rejected: false,
          });
        }, 3000);
      }
    });
  };

  const completeTask = () => {
    if (data?.task?.status == TaskStatus.Completed) {
      dispatch(
        updateActivityTask({
          data: {
            status: TaskStatus.NotCompleted,
            sk: activity.sk,
            createdAt: activity.createdAt,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            taskId: data?.task?.id,
            timelineId: data.id,
            tCreatedAt: data.createdAt,
          },
          callback: res => {
            console.log('updateActivityTask', res, tempTimelineContent[index]);
            tempTimelineContent[index] = {
              ...tempTimelineContent[index], task: {
                ...tempTimelineContent[index].task,
                ...res.results.timeline.task
              }
            }
            setTimelineContent(tempTimelineContent);
            deleteModalHandle(false)
          },
          token: auth.token,
          activityId: activity.id,
        }),
      );
    } else {
      dispatch(
        updateActivityTask({
          data: {
            status: TaskStatus.Completed,
            sk: activity.sk,
            createdAt: activity.createdAt,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            taskId: data?.task?.id,
            timelineId: data.id,
            tCreatedAt: data.createdAt,
          },
          callback: res => {
            console.log('updateActivityTask', res, tempTimelineContent[index]);
            tempTimelineContent[index] = {
              ...tempTimelineContent[index], task: {
                ...tempTimelineContent[index].task,
                ...res.results.timeline.task
              }
            }
            setTimelineContent(tempTimelineContent);
            deleteModalHandle(false)
          },
          token: auth.token,
          activityId: activity.id,
        }),
      );
    }
  }

  const toggleUserPopup = () => {
    setUserPopupStatus(!userPopupStatus);
  };

  return (
    <Popup
      open={deleteModal}
      closeOnDocumentClick={false}
      contentStyle={{
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
      }}
    >
      <div className="space-y-5 relative bg-white w-[400px] rounded-[20px] p-10  max-[500px]:p-5 max-[500px]:h-screen max-[500px]:w-full  max-[500px]:overflow-y-scroll max-[500px]:rounded-none">
        {screens.menuScreen ? (<div>
          <div className="flex items-center gap-3">
            <img
              src={showDelete ? backBtn : Cross}
              alt="cross"
              className={`cursor-pointer ${showDelete ? " w-[10px]" : " w-[20px]"
                } `}
              onClick={() =>
                showDelete ? showDeleteHandle(false) : deleteModalHandle(false)
              }
            />

            <p className="text-jrnyfy-textprimary font-semibold w-full text-center">
              Timeline Options
            </p>
          </div>

          <div className="text-center space-y-3">
            {isTask && (<button
              onClick={() => toggleUserPopup()}
              className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              ASSIGN IT
            </button>)}
            {userPopupStatus && (<UserListPopup
              toggle={toggleUserPopup}
              popupStatus={userPopupStatus}
              isAddParticipant={true}
              activity={activity}
              showAddBtn={false}
              filter={2}
              filterId={activity.id}
              filterType={1}
            />)}
            <button
              onClick={() => {
                if (isProgressUpdate) {
                  progressPicModalHandle(true)
                } else {

                  setScreens({ menuScreen: 0, editScreen: true, dueDateScreen: 0 })
                }
              }}
              className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              EDIT
            </button>
            {progressPicModal && (
              <AddProgress
                setDataLoading={setDataLoading}
                setTimelineContent={setTimelineContent}
                timeLineContent={timeLineContent}
                modalState={progressPicModal}
                modalControl={progressPicModalHandle}
                jrnyActivity={activity}
                data={data}
                index={index}
                isUpdate={true}
                modalHandle={deleteModalHandle}
              />
            )}
            {isTask && (<button
              onClick={() => setScreens({ menuScreen: 0, editScreen: 0, dueDateScreen: true })}
              className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              ADD A DUE DATE
            </button>)}
            {isTask && (<button
              onClick={() => completeTask()}
              className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              {data?.task?.status != TaskStatus.Completed
                ? 'MARK COMPLETE'
                : 'MARK INCOMPLETE'}
            </button>)}
            <button
              onClick={() => removeTimelineItemHandle()}
              className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
            >
              Remove
            </button>
          </div>
        </div>) : screens.editScreen ? (<div className="h-full space-y-5">
          <button
            onClick={() => setScreens({ menuScreen: true, editScreen: 0, dueDateScreen: 0 })}
            className="bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            Back
          </button>
          <div className="space-y-3">
            <input
              type="text"
              placeholder="add task title"
              className="bg-gray-100 w-full text-jrnyfy-textprimary p-[10px_20px] rounded-[50px] focus:outline-none"
              value={updatedMilestone.text}
              onChange={e =>
                setUpdatedMilestone({ ...updatedMilestone, text: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="add description"
              className="bg-gray-100 w-full text-jrnyfy-textprimary p-[10px_20px] rounded-[50px] focus:outline-none"
              value={updatedMilestone.desc}
              onChange={e =>
                setUpdatedMilestone({ ...updatedMilestone, desc: e.target.value })
              }
            />
          </div>
          <button
            onClick={() => {
              setMilestoneUpdateLoading(true);
              dispatch(
                updateActivityTask({
                  data: {
                    text: updatedMilestone.text,
                    description: updatedMilestone.desc,
                    sk: activity.sk,
                    createdAt: activity.createdAt,
                    timelineId: data.id,
                    tCreatedAt: data.createdAt,
                  },
                  callback: res => {
                    tempTimelineContent[index] = { ...tempTimelineContent[index], ...res.results.timeline }
                    console.log('MilestongeTimeline', tempTimelineContent, res);
                    setTimelineContent(tempTimelineContent);
                    setMilestoneUpdateLoading(false);
                    deleteModalHandle(false)
                  },
                  token: auth.token,
                  activityId: activity.id,
                }),
              );
            }}
            className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            Save
          </button>
        </div>) : screens.dueDateScreen && (<div className="space-y-5">
          <button
            onClick={() => setScreens({ menuScreen: true, editScreen: 0, dueDateScreen: 0 })}
            className="bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            Back
          </button>
          <div>
            <DateTimePicker onChange={setDueDate} value={dueDate} />
          </div>
          <button
            onClick={() => {
              dispatch(
                updateActivityTask({
                  data: {
                    dueDate: dueDate,
                    sk: activity.sk,
                    createdAt: activity.createdAt,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    taskId: data?.task?.id,
                    timelineId: data.id,
                    tCreatedAt: data.createdAt,
                  },
                  callback: res => {
                    console.log('duedateaddeA', res, tempTimelineContent, index);
                    tempTimelineContent[index] = {
                      ...tempTimelineContent[index], task: {
                        ...tempTimelineContent[index].task,
                        ...res.results.timeline.task
                      }
                    }
                    console.log('duedateaddeA', tempTimelineContent[index])
                    setTimelineContent(tempTimelineContent);
                    deleteModalHandle(false)
                  },
                  token: auth.token,
                  activityId: activity.id,
                }),
              );
            }}
            className="w-full bg-jrnyfy-themeColor p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-white font-semibold  transition-all font-SourceSansPro"
          >
            Save
          </button>
        </div>)}

      </div>
    </Popup>
  );
};

export default TimelineOptionsModal;
