import { Loader } from "@googlemaps/js-api-loader";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import { FreeMode } from "swiper";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import addPlaceIcon from "../../assets/images/button_menu_places.svg";
import Cross from "../../assets/images/xmark-solid.svg";
import { addLocationIntoActivity } from "../../redux/slices/mobile/activitySlice";
import {
  authSelector,
  updateProfile,
} from "../../redux/slices/mobile/authSlice";
import {
  getTopLocationByTextSearch,
  goggleMapSelector,
  addLocationIntoCurrentLocation,
} from "../../redux/slices/mobile/goggleMapSlice";
import Persons from "../Persons/Persons";
import { PhotoSize, PhotoSource, TaskType, ViewGroupType } from "../utils/subjects";
import { buildUrlToSize } from "../../res/commonFunctions";
import backBtn from "../../assets/icons/back.png";

const AddPlaces = ({
  setDataLoading,
  setTimelineContent,
  timeLineContent,
  showBackBtn,
  control,
  placeAdd,
  placeArr,
  fromJrny,
  jrnyActivity,
  showFloatingMenuHandle,
  friendControl,
  placeControl,
  jrnyCreateActivity,
  locState,
  activity
}) => {
  let [gValue, setGValue] = useState();
  let [nearByValue, setNearByValue] = useState([]);
  let [latLong, setLatLong] = useState({ lat: "", long: "" });
  const { pageToken } = useSelector(goggleMapSelector);
  const [currentLocation, setCurrentLocation] = useState();
  const [placePredictions, setPlacePredictions] = useState();
  console.log("Current Location", currentLocation);
  console.log("Current pageToken", pageToken);
  let dispatch = useDispatch();
  let authinfo = useSelector(authSelector).auth;
  const inputRef = useRef();

  // const { placesService, getPlacePredictions, placePredictions } =
  //   usePlacesService({
  //     apiKey: 'AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE',
  //   });

  // const loader = new Loader({
  //   apiKey: 'AIzaSyDDrhX4vwjxfraCF0eW31jKVRAt8Hwd8IE',
  //   version: 'weekly',
  //   libraries: ['places'],
  // });

  let placeSelect = (data) => {
    let check = placeArr.some((val) => val.place_id === data.place_id);
    if (check) {
      let checked = placeArr.filter((val) => val.place_id !== data.place_id);
      placeAdd((state) => checked);
    } else {
      placeAdd((state) => state.concat(data));
      if (fromJrny) {
        control();
        console.log('LocationDetails', data)
        const newLocation = {
          loading: true,
          type: 1,
          user: authinfo?.user,
          activity: {
            name: activity.name,
            sk: activity.sk,
            createdAt: activity.createdAt,

          },
          task: {
            location: {
              type: 0,
              coordinates: [
                data.geometry.location.lat instanceof Function
                  ? data.geometry.location.lat()
                  : data.geometry.location.lat,
                data.geometry.location.lng instanceof Function
                  ? data.geometry.location.lng()
                  : data.geometry.location.lng,
              ],
              text: data.name,

              photo: {
                photoReference: data?.photos[0].photo_reference
                  ? data?.photos[0].photo_reference
                  : data?.photos[0].getUrl(),
                photoSource: data?.photos[0].photo_reference
                  ? PhotoSource.GooglePlace
                  : PhotoSource.GooglePlaceFullURL,
              },
            },
          },
        };
        let tempTimelineContent = [newLocation, ...timeLineContent];
        setTimelineContent([newLocation, ...timeLineContent]);
        // setDataLoading(true);
        if (showFloatingMenuHandle) {
          showFloatingMenuHandle(false);
        }
        dispatch(
          addLocationIntoActivity({
            data: {
              location: {
                coordinates: {
                  lon:
                    data.geometry.location.lng instanceof Function
                      ? data.geometry.location.lng()
                      : data.geometry.location.lng, lat:
                    data.geometry.location.lat instanceof Function
                      ? data.geometry.location.lat()
                      : data.geometry.location.lat,
                },
                photo: {
                  photoReference: data?.photos[0].photo_reference
                    ? data?.photos[0].photo_reference
                    : data?.photos[0].getUrl(),
                  photoSource: data?.photos[0].photo_reference
                    ? PhotoSource.GooglePlace
                    : PhotoSource.GooglePlaceFullURL,
                },
                id: data.place_id,
                name: data.name,
              },

              sk: jrnyActivity.sk,
              createdAt: jrnyActivity.createdAt,
              type: TaskType.Location,
              groupType: ViewGroupType.TaskLocation
            },
            callback: (res) => {
              console.log("addLocationss", res)
              tempTimelineContent[0] = res?.timeline;
              setTimelineContent(tempTimelineContent);
            },

            token: authinfo.token,
            id: jrnyActivity.id,
            user: authinfo.user,
          })
        )
      }
    }
  };

  // let nearByLoader = (lat, long) => {
  //   loader
  //     .load()
  //     .then((google) => {
  //       var latlong = new google.maps.LatLng(lat, long);
  //       let map = new google.maps.Map(document.getElementById('map'), {
  //         center: latlong,
  //         zoom: 15,
  //       });

  //       var request = {
  //         location: latlong,
  //         radius: '2500',
  //         type: 'tourist_attraction',
  //       };

  //       let service = new google.maps.places.PlacesService(map);
  //       service.nearbySearch(request, (result) => {
  //         setNearByValue(result.slice(0, 100));
  //       });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const getSearchedPredictions = (searchedText) => {
    dispatch(
      getTopLocationByTextSearch({
        data: {
          type: "autocomplete",
          params: [`input=${searchedText}`],
        },
        token: authinfo?.token,
        callback: (res) => {
          setPlacePredictions(res?.predictions);
        },
      })
    );
  };

  const getTopPlaces = (lat, long) => {
    dispatch(
      getTopLocationByTextSearch({
        data: {
          type: "nearbysearch",
          params: [
            `location=${lat},${long}`,
            "types=tourist%attraction",
            "radius=20000",
            "keyword=things%20to%20do",
            "rankby=prominence",
          ],
        },
        token: authinfo?.token,
        callback: (res) => {
          setCurrentLocation([...res.results]);
        },
      })
    );
  };
  const getTopPlacesTextSearch = (val, placeName) => {
    dispatch(
      getTopLocationByTextSearch({
        data: {
          type: "textsearch",
          params: [
            `query=tourist+place+in+${placeName}`,
            "types=tourist_attraction",
          ],
        },

        token: authinfo?.token,
        callback: (res) => {
          setCurrentLocation([val, ...res.results]);
        },
      })
    );
  };

  const getPlaceDetails = (Value, isAdd) => {
    dispatch(
      getTopLocationByTextSearch({
        data: {
          type: "details",
          params: [`place_id=${Value.place_id}`],
        },

        token: authinfo?.token,
        callback: (res) => {
          let lat = res?.result?.geometry?.location?.lat;
          let long = res?.result?.geometry?.location?.lng;

          // getTopPlaces(lat, long);

          getTopPlacesTextSearch(res?.result, res?.result?.name);
          if (isAdd) {
            placeSelect(res?.result);
          }
          setLatLong({ lat: lat, long: long });
        },
      })
    );
  };

  // const getPlaceDetails = (gValue, isAdd) => {
  //   // placesService?.getDetails(
  //   //   {
  //   //     placeId: gValue?.place_id,
  //   //   },
  //   //   (placeDetails) => {
  //   //     let lat = placeDetails.geometry.location.lat();
  //   //     let long = placeDetails.geometry.location.lng();
  //   //     console.log('Place Details', placeDetails);
  //   //     // getTopPlaces(lat, long);

  //   //     getTopPlacesTextSearch(placeDetails.name);
  //   //     if (isAdd) {
  //   //       placeSelect(placeDetails);
  //   //     }
  //   //     setLatLong({ lat: lat, long: long });
  //   //   }
  //   // );

  // };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((e) => {
      getTopPlaces(e.coords.latitude, e.coords.longitude);
      console.log("LOcationCurrent", e.coords.latitude);
      setLatLong({ lat: e.coords.latitude, long: e.coords.longitude });
      //if (!authinfo.user.coordinates) {
      dispatch(
        updateProfile({
          data: {
            coordinates: [e.coords.longitude, e.coords.latitude],
          },
          token: authinfo.token,
        })
      );
      //}
    });
  }, []);

  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  return (
    <motion.div
      key="places"
      initial={{ x: "-100vw", scale: 0 }}
      animate={{ x: 0, scale: 1 }}
      exit={{ x: "-100vw", scale: 0, opacity: 0 }}
      transition={{ duration: 0.1 }}
      onClick={(e) => {
        // getPlacePredictions({ input: '' });
      }}
    >
      <div className="space-y-3">
        <div className="flex items-center ">
          {/* <img src={addPlaceIcon} alt='place' className='max-w-[45px]' /> */}

          {showBackBtn && (
            <div className="flex justify-center items-center">
              <button onClick={() => control()}>
                <img
                  src={backBtn}
                  alt="back button"
                  className="w-[15px] h-[25px]"
                />
              </button>
            </div>
          )}
          <p className="text-center w-full min-[767px]:text-[20px] font-semibold text-jrnyfy-textprimary">
            Where will the journey take place ?
          </p>
        </div>

        {/* <div className=''>
          <Swiper
            slidesPerView={6}
            spaceBetween={0}
            freeMode={true}
            modules={[FreeMode]}
          >
            {placeArr.map((val) => (
              <SwiperSlide key={val.place_id}>
                <div className='relative text-center mt-[7px] w-[55px]'>
                  <img
                    src={Cross}
                    alt='remove'
                    className='w-[10px] absolute top-[-9px] right-0 cursor-pointer'
                    onClick={() => {
                      placeSelect(val);
                    }}
                  />
                  <img
                    src={
                      val?.photos === undefined
                        ? addPlaceIcon
                        : buildUrlToSize({
                            photoReference: val?.photos[0].photo_reference
                              ? val?.photos[0].photo_reference
                              : val?.photos[0].getUrl(),
                            photoSource: val?.photos[0].photo_reference
                              ? PhotoSource.GooglePlace
                              : PhotoSource.GooglePlaceFullURL,
                          })
                    }
                    alt={val.name}
                    className='w-[45px] h-[45px] rounded-[50%] inline-block'
                  />
                  <p className='text-[10px] font-semibold leading-[10px] max-w-[55px]'>
                    {val.name}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div> */}

        <div className="relative">
          <div className="relative">
            <input
              type="search"
              ref={inputRef}
              placeholder="search where your journey will take place?"
              className="bg-gray-100 w-full text-jrnyfy-textprimary p-[10px_20px] rounded-[50px] focus:outline-none"
              onChange={(e) => getSearchedPredictions(e.target.value)}
            />
            {/* <button className="w-[25px] h-[25px] absolute top-2/4 translate-y-[-50%] right-[25px] border-4 border-solid border-gray-400 rounded-[50%]"></button> */}
          </div>

          {placePredictions?.length > 0 && (
            <div
              className={`bg-gray-100 w-full z-10 mx-auto rounded-2xl p-5 absolute top-[50px] left-[28pnearByLoaderx] `}
            >
              {placePredictions?.map((val, ind) => (
                <div
                  key={ind}
                  className="flex justify-between items-center pt-2 cursor-pointer"
                  onClick={() => {
                    // setGValue(val);
                    getPlaceDetails(val, false);
                    setPlacePredictions();
                  }}
                >
                  <div className="flex items-center">
                    <img
                      src={addPlaceIcon}
                      alt="add places Icon"
                      className="w-[30px] mr-1"
                    />
                    <div>
                      <h6 className="text-[15px] text-jrnyfy-textprimary font-normal leading-[15px]">
                        {val.description}
                      </h6>
                    </div>
                  </div>
                  <button
                    className={`border border-solid border-jrnyfy-themeColor p-[0px_10px] rounded-[50px] font-SourceSansPro ${placeArr.some((res) => res.place_id === val.place_id)
                      ? "bg-jrnyfy-themeColor text-white"
                      : "text-jrnyfy-color1"
                      }`}
                    onClick={() => {
                      // setGValue(val);
                      // getTopPlacesTextSearch();
                      getPlaceDetails(val, true);
                      setPlacePredictions();
                    }}
                  >
                    {placeArr.some((res) => res.place_id === val.place_id)
                      ? "added"
                      : "add"}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {locState && (
          <div className="text-end space-x-3">
            <button
              className="bg-jrnyfy-themeColor p-[5px_10px] max-[767px]:p-[3px_8px]  rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro"
              onClick={() => {
                placeControl();
                friendControl();
              }}
            >
              add friends
            </button>
            <button
              className="bg-jrnyfy-themeColor p-[5px_10px] max-[767px]:p-[3px_8px]  rounded-[50px] text-white font-semibold text-jSecTitle hover:scale-[1.1] transition-all font-SourceSansPro"
              onClick={() => {
                control();
                jrnyCreateActivity();
              }}
            >
              START Journey
            </button>
          </div>
        )}

        <div className="space-y-2">
          <p>nearby suggested places</p>
          <div className="h-[250px] min-h-[250px] overflow-y-scroll py-3 pr-3 flex flex-col justify-between gap-5 max-[600px]:h-[calc(100vh-218px)]">
            <div className="max-[350px]:space-y-5">
              {currentLocation?.length >= 1
                ? currentLocation.map((val, ind) => (
                  <div
                    key={ind}
                    className="flex justify-between items-center w-full max-[350px]:flex-col w-full max-[350px]:items-start"
                  >
                    <Persons
                      avater={
                        val?.photos === undefined
                          ? addPlaceIcon
                          : buildUrlToSize({
                            photoReference:
                              val?.photos?.[0]?.photo_reference,
                            photoSource: PhotoSource.GooglePlace,
                          })
                      }
                      name={val.name}
                      rating={val.rating}
                      userid={
                        val.vicinity ? val.vicinity : val.formatted_address
                      }
                      className="w-[55px] h-[55px] rounded-[50%] text-white max-w-none"
                      nameStyle="text-jSecTitle font-semibold leading-[17px]"
                    />

                    <div className="max-[350px]:w-full">
                      <button
                        className={`max-[350px]:w-full p-[5px_15px] font-SourceSansPro rounded-[50px] font-semibold ${placeArr.some(
                          (res) => res.place_id === val.place_id
                        )
                          ? "bg-jrnyfy-themeColor text-white"
                          : "border-jrnyfy-textprimary border border-solid"
                          }`}
                        onClick={() => {
                          placeSelect(val);
                        }}
                      >
                        {placeArr.some((res) => res.place_id === val.place_id)
                          ? "remove"
                          : "add"}
                      </button>
                    </div>
                  </div>
                ))
                : ""}
            </div>
            {pageToken && currentLocation?.length >= 1 && (
              <div className="flex justify-center items-center">
                <button
                  onClick={() => {
                    // dispatch(
                    //   getTopLocationByTextSearch({
                    //     data: {
                    //       type: 'nearbysearch',
                    //       params: [
                    //         `location=${latLong.lat},${latLong.long}`,
                    //         'radius=50000',
                    //         'keyword=things%20to%20do',
                    //         'rankby=prominence',
                    //         `pagetoken=${pageToken}`,
                    //       ],
                    //     },
                    //   })
                    // );
                    dispatch(
                      getTopLocationByTextSearch({
                        data: {
                          type: "textsearch",
                          params: [
                            "types=tourist_attraction",
                            `pagetoken=${pageToken}`,
                          ],
                        },
                        token: authinfo?.token,
                      })
                    );
                  }}
                  type="button"
                  className="cursor-pointer border border-jrnyfy-themeColor hover:bg-jrnyfy-themeColor  p-[6px_15px_6px_15px] text-[14px] italic rounded-[50px] text-jrnyfy-themeColor font-semibold text-jSecTitle hover:scale-[1.1] hover:text-white transition-all font-SourceSansPro"
                >
                  show more places
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {showBackBtn && (
        <div className='flex justify-center items-center'>
          <button
            className='p-[5px_10px] border border-solid border-jrnyfy-textprimary text-jrnyfy-color1 rounded-[50px] font-bold'
            onClick={() => {
              control();
            }}
          >
            back
          </button>
        </div>
      )} */}
    </motion.div>
  );
};

export default AddPlaces;
