import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css/free-mode";
import { buildUrlToSize } from "../../utils/build-image-url";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/mobile/authSlice";
import { activitySelector } from "../../redux/slices/mobile/activitySlice";
import { PhotoSize, YesNo } from "../utils/subjects";
import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useLocation, useNavigate } from "react-router-dom";
import Option from "../../assets/images/ellipsis-vertical-solid.svg";
import Moment from "react-moment";
import {
  getJourneyNavOption,
  getUserNavOption,
} from "../../utils/get-nav-option";
import PopUpImageGallery from "../popUpImageGallery/PopUpImageGallery";
import Cross from "../../assets/images/xmark-solid.svg";

import Thumbnail from "../thumbnails/Thumbnail";
import Icons from "../Icons/Icons";
import TimelineOptionsModal from "../TimelineOptionsModal/TimelineOptionsModal";
import ImageGalleryRenderer from "../ImageGallery/ImageGalleryRenderer";
import { timelineDataContext } from "../jrnyTimline/JrnyTimline";
import DynamicGridGallery from "../SharedComponents/DynamicGridGallery";
import SuccessIcon from "../../assets/icons/success-icon.svg";
import { progress } from "framer-motion";

const Progress = ({ isEditable, isTimeline, index, data }) => {
  let [popUpImage, setpopUpImage] = useState({
    status: false,
    value: null,
  });
  const location = useLocation()
  const { activity } = useSelector(activitySelector);

  let modalCondition = (imageUrl) => {
    setpopUpImage((state) => ({ status: !state.status, value: imageUrl }));
  };
  let avater = buildUrlToSize(
    data?.feed ? data?.feed?.user?.thumbnail : data?.user?.thumbnail,
    PhotoSize.Large
  );
  const [isReadMore, setIsReadMore] = useState(false);
  const user = data?.feed?.user ? data?.feed?.user : data?.user;
  let userName = data?.user?.name;
  let photos = data?.feed?.files || data?.files;
  let authinfo = useSelector(authSelector).auth;
  let dispatch = useDispatch();
  let fName = data?.feed?.user?.name.slice(0, 1);
  let mNamePos = data?.feed?.user?.name.search(" ");
  let mName = data?.feed?.user?.name.slice(mNamePos + 1, mNamePos + 2);
  let altTitle = fName + mName;
  const journeyName = data?.activity?.name ? data?.activity?.name : activity?.name;
  let navigate = useNavigate();
  let journeyTimeLine = useSelector(activitySelector).journeyTimeLine;
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [actionStates, setActionStates] = useState({
    loading: false,
    fulfilled: false,
    rejected: false,
  });

  const deleteModalHandle = (action) => {
    setDeleteModal(action);
  };

  const openImageGallery = () => {
    setShowImageGallery(true);
    document.body.style.overflowY = "hidden";
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = "scroll";
  };

  const openGallery = (galleryIndex, clickedItem) => {
    if (!data.loading) {
      navigate('/media-detials', {
        state: {
          item: { clickedItem, data: { doc: { sk: data.sk, id: data.id } } }, timelineId: data.id, galleryIndex: galleryIndex, activity: data.activity, previousLocation: location
        },
      })
    }
  }
  console.log("DataProgress", data)
  return (
    <>
      <div
        onClick={() => {
          // navigate(`/jrny/${data.id}`);
          navigate(getJourneyNavOption(data));
        }}
        className="flex justify-between items-start cursor-pointer">
        <div className="relative">
          <div className="absolute top-0 left-[-24px] z-10">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Small}
            />
          </div>

          {isTimeline ? (
            <p
              className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold cursor-pointer"
              onClick={() => {
                // navigate(`/jrny/${data.id}`);
                navigate(getJourneyNavOption(data));
              }}
            >
              <span className="text-jrnyfy-title">
                {userName} {data.finished ? 'Success Story' : 'Journey Progress'} {' '}
              </span>
              {journeyName}
            </p>
          ) : (
            <p className="pl-[30px] md:px-[30px] max-[767px]:text-[14px] text-jrny font-semibold">
              <span className="text-jrnyfy-title">
                {userName} {data.finished ? 'Success Story' : 'Journey Progress'} {' '}
              </span>
              {journeyName}
            </p>
          )}

          <p className="pl-[30px] text-[10px] font-semibold text-jrnyfy-textprimary">
            added by {user.name}, created{" "}
            <Moment fromNow>{data?.createdAt}</Moment>
          </p>
        </div>

        {isEditable == true &&
          !data?.feed?.user &&
          (data.loading ? (
            <div>
              <div className="spinner"></div>
            </div>
          ) : (
            <div className="ml-[10px] md:ml-[30px]">
              <Icons
                iconClick={() => deleteModalHandle(true)}
                icon={Option}
                alt="Option"
                width="25px"
              />
            </div>
          ))}
      </div>
      {deleteModal && (
        <TimelineOptionsModal
          index={index}
          data={data}
          actionStates={actionStates}
          deleteModal={deleteModal}
          deleteModalHandle={deleteModalHandle}
          isProgressUpdate={true}
        />
      )}

      <div className="mt-4 ml-[20px] w-auto">

        {data?.files?.length > 0 ? (<DynamicGridGallery
          items={data}
          autoPlay={false}
          showVidThumb={false}
          openGallery={openGallery}
        />) : (
          <div className="flex justify-center items-end py-10">
            <Thumbnail
              ShowName={false}
              btnFollow={false}
              data={user}
              PhotoSize={PhotoSize.Medium}
              thumbnailContainerStyle={'!w-[110px] !h-[110px]'}
            />
            <img className="ml-[-35px] z-10" src={SuccessIcon} />
          </div>
        )}
        <div className="mb-[20px] mt-[10px]">
          <p className="max-[767px]:text-[14px]">
            {!isReadMore ? data.text?.substring(0, 100) : data.text}
            {data.text?.length >= 100 && (<span onClick={() => {
              if (isReadMore) {
                setIsReadMore(false);
              } else {
                setIsReadMore(true);
              }
            }} className="max-[767px]:text-[20px] text-jrny text-jrnyfy-title font-extrabold cursor-pointer">
              {!isReadMore ? ' Read More' : ' Show Less'}
            </span>)}
          </p>

        </div>

      </div>

      {showImageGallery && (
        <ImageGalleryRenderer
          timelineIndex={index}
          closeImageGallery={closeImageGallery}
          galleryCurrentIndex={galleryCurrentIndex}
          setGalleryCurrentIndex={setGalleryCurrentIndex}
          photos={photos}
        />
      )}
    </>
  );
};

export default Progress;
