import { buildUrlToSize } from '../../utils/build-image-url';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import LikeIcon from '../../assets/images/feed_component_like.svg';
import Share from '../../assets/images/feed_component_share.svg';
import Comment from '../../assets/images/feed_component_comment.svg';
import Trophy from '../../assets/images/feed_component_trophy.svg';
import Ideas from '../../assets/images/feed_component_idea.svg';
import Option from '../../assets/images/ellipsis-vertical-solid.svg';
import Visible from '../../assets/images/visibility.svg';
import Icons from '../Icons/Icons';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import LikeModal from '../FeedCard/likeModal';
import CommentsModal from '../FeedCard/commentsModal';
import Login from '../Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import {
  likeAnActivity,
  authSelector,
} from '../../redux/slices/mobile/authSlice';
import BadegsModal from '../FeedCard//badgeModal';
import {
  getLikesOfActivity,
  feedSelector,
} from '../../redux/slices/mobile/feedSlice';
import Heart from '../../assets/images/heart_icon.svg';
import ActivityMangeModal from '../FeedCard/ActivityMangeModal';
import {
  activitySelector,
  updateActivityName,
} from '../../redux/slices/mobile/activitySlice';
import ImageGallery from 'react-image-gallery';
import cancelBtnSvg from '../../assets/images/Cancel.svg';
import { ContentFilter, LikeOrCommentType, PhotoSize, TimelineType } from '../utils/subjects';
import ShareModal from '../FeedCard/ShareModal';
import { onTypeChange } from '../utils/typing-change';
import ToastModal from '../ToastModal/ToastModal';
import ImageGalleryRenderer from '../ImageGallery/ImageGalleryRenderer';
import UserThumbsWithCounting from '../UsersThumbsWithCounting/UserThumbsWithCounting';
import ParticipantListModal from '../ParticipantListModal/ParticipantListModal';
import { getNumberFormat, hash } from '../utils/static/global_functions';
import FeedOptionModal from '../FeedCard/FeedOptionModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { commentParser } from '../../redux/slices/mobile/elasticParser';

const TimeLineSlider = ({ setTimelineContent, timeLineContent, data }) => {
  console.log('TimelineData', data)
  let authInfo = useSelector(authSelector);
  let Feed = useSelector(feedSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const { sortedGalleryObjects, activity } = useSelector(activitySelector);
  let dispatch = useDispatch();
  const [comments, setComments] = useState([])
  let [mState, setMstate] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryCurrentIndex, setGalleryCurrentIndex] = useState(0);
  let [likesAmount, setLikesAmount] = useState(data.likes);
  let [condLogin, setCondLogin] = useState(false);
  let gallery = data?.gallery || data?.photos || [];
  let [lModal, setLModal] = useState(false);
  let [cModal, setCModal] = useState(false);
  let [bModal, setBModal] = useState(false);
  const [activityManageModal, setActivityManageModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  let [like, setLike] = useState(false);
  const [isBorkenUrl, setIsBrokenUrl] = useState(false)
  const [toastModal, setToastModal] = useState({ status: false, value: '' });
  const [typeInput, setTypeInput] = useState({
    typing: true,
    text: data.name,
    typingTimeout: 0,
  });

  const getComments = async () => {
    setComments(await commentParser(activity.comments))
  }

  useEffect(() => {
    getComments()
  }, [activity.comments])

  const [showOptionModal, setShowOptionModal] = useState(false);
  const [successStoryDescription, setSuccessStoryDescription] = useState();
  const showOptionModalHandle = (action) => {
    // if (action) {
    //   window.history.pushState(null, '', location.pathname);
    // } else {
    //   navigate(-1);
    // }
    setShowOptionModal(action);
  };

  const [showParticipantModal, setShowParticipantModal] = useState();

  const showParticipantModalHandle = (action) => {
    setShowParticipantModal(action);
  };

  const [updatedInput, setUpdatedInput] = useState();

  const toastModalHandle = (status, value) => {
    setToastModal({
      status: status,
      value: value,
    });
  };
  console.log('toastModal', toastModal, updatedInput);
  useEffect(() => {
    setLikesAmount(data.likes);
  }, [data.likes]);

  let lModalControl = () => {
    if (authInfo?.auth?.user?.verified) {
      setLModal((state) => !state);
    } else {
      setCondLogin((state) => !state);
    }
  };

  let cModalControl = () => {
    if (authInfo?.auth?.user?.verified) {
      setCModal((state) => !state);
    } else {
      setCondLogin((state) => !state);
    }
  };

  let bModalControl = () => {
    setBModal((state) => !state);
  };

  const activityManageModalHandle = () => {
    console.log('click');
    setActivityManageModal((prevState) => !prevState);
  };

  const shareModalHandle = () => {
    setShareModal((prevState) => !prevState);
  };

  let likeControl = () => {
    if (authInfo?.auth?.user?.verified) {
      if (like) {
        setLike(false);
        dispatch(
          likeAnActivity({
            data: {
              operator: -1,
              createdAt: data.createdAt,
              sk: data.sk,
            },
            token: authInfo?.auth?.token,
            id: data.id,
          })
        );
        setLikesAmount((state) => state - 1);
      } else {
        setLike(true);
        dispatch(
          likeAnActivity({
            data: {
              operator: 1,
              createdAt: data.createdAt,
              sk: data.sk,
            },
            token: authInfo?.auth?.token,
            id: data.id,
          })
        );
        setLikesAmount((state) => state + 1);
      }
    } else {
      setCondLogin((state) => !state);
    }
  };

  useEffect(() => {
    dispatch(
      getLikesOfActivity({
        data: {
          from: 0,
          size: 1000,
          filterId: data.id,
          value: '',
        },
        token: authInfo.auth.token,
        // activityId: data.id,
        callback: (e) => { },
      })
    );
  }, [data.id]);

  useEffect(() => {
    if (Feed.userListLikes && authInfo?.auth?.user) {
      const isLiked = Feed.userListLikes.some(
        (like) => like.id === authInfo?.auth?.user.id
      );
      if (isLiked) {
        setLike(true);
      } else {
        setLike(false);
      }
    } else {
      setLike(false);
    }
  }, [Feed.userListLikes]);

  // console.log(Feed.userListLikes);

  const openImageGallery = (clickedItem, galleryIndex) => {
    // navigation.navigate("MediaDetails", { item:{data:{doc:{id:data[galleryIndex].id,sk:activity.sk}}}, gallery: data,galleryIndex:galleryIndex,activity:activity })

    navigate('/media-detials', {
      state: {
        item: { clickedItem, data: { doc: { sk: activity.sk, id: activity.id } } }, galleryIndex: galleryIndex, activity, previousLocation: location
      },
    })
    // setShowImageGallery(true);
    // document.body.style.overflowY = 'hidden';
  };

  const closeImageGallery = () => {
    setShowImageGallery(false);
    document.body.style.overflowY = 'scroll';
  };

  // useEffect(() => {
  //   setGalleryImages([]);
  //   sortedGalleryObjects?.forEach((item) => {
  //     const imgSrc = buildUrlToSize(item, PhotoSize.Large);
  //     console.log(imgSrc);
  //     setGalleryImages((prevState) => [
  //       ...prevState,
  //       {
  //         original: imgSrc,
  //         thumbnail: imgSrc,
  //         thumbnailClass: '!h-[50px]',
  //         thumbnailHeight: '100%',
  //         thumbnailWidth: '100%',
  //       },
  //     ]);
  //   });
  // }, [sortedGalleryObjects]);

  useEffect(() => {
    timeLineContent?.every((item) => {
      console.log('Timeline Loops');
      if (item.type == TimelineType.FinishedAt) {
        setSuccessStoryDescription(item);
        return true;
      }
    });
  }, [timeLineContent]);

  useEffect(() => {
    if (updatedInput != undefined) {
      if (updatedInput?.length < 5 || updatedInput?.length > 200) {
        setTypeInput({ ...typeInput, text: data.name });
        toastModalHandle(
          true,
          'activity name must be between 5 and 200 characters'
        );
      } else {
        dispatch(
          updateActivityName({
            data: {
              name: updatedInput,
              sk: activity.sk,
              createdAt: activity.createdAt,
            },
            token: authInfo?.auth?.token,
            id: activity.id,
            callback: () => {
              //toastModalHandle(true, 'activity name updated successfully');
            },
          })
        );
      }
    }
  }, [updatedInput]);
  console.log("SLiderGallery", sortedGalleryObjects)
  const journeyNameToNumber = hash(activity.name) % authInfo.templatePhotoCount || 1;
  return (
    <div className='relative max-w-[545px]'>
      {condLogin && (
        <Login
          mState={condLogin}
          setMState={() => {
            setCondLogin((state) => !state);
          }}
        />
      )}
      <div className='text-overly'>
        <Swiper
          style={{
            '--swiper-pagination-color': 'white',
            '--swiper-pagination-bullet-inactive-color': '#999999',
            '--swiper-pagination-bullet-inactive-opacity': '1',
            '--swiper-navigation-color': '#FF0077',
            '--swiper-navigation-size': '40px',
            fontWeight: '900',
          }}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          navigation={{
            enabled: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {sortedGalleryObjects.length >= 1
            ? sortedGalleryObjects?.map((val, ind) => (
              <SwiperSlide key={ind}>
                <img
                  src={isBorkenUrl ? `https://storage.googleapis.com/jrnyfy/mobile/template-${journeyNameToNumber}-xlr.jpeg` : buildUrlToSize(val, PhotoSize.Large)}
                  alt='Place'
                  className='aspect-video w-full max-h-[200px] object-cover'
                  onClick={() => {
                    //setGalleryCurrentIndex(ind);
                    openImageGallery(val, ind);
                  }}
                  onError={() => {
                    setIsBrokenUrl(true)
                  }}
                />
              </SwiperSlide>
            ))
            : null}
        </Swiper>
        <div className='absolute z-20 w-full text-white bottom-[55px] left-0 px-[20px] text-[18px]'>
          {data?.isEditable == 1 ? (
            <input
              type='text'
              value={typeInput.text}
              className='bg-transparent w-full focus:outline-none'
              onChange={onTypeChange(typeInput, setTypeInput, (value) => {
                setUpdatedInput(value);
              })}
            />
          ) : (
            <p>{data.name}</p>
          )}
        </div>
      </div>
      <div className='flex items-center justify-between absolute bg-white rounded-t-[15px] bottom-0 left-0 z-10 px-[15px] py-[5px] border-b-2 border-solid border-jrnyfy-border w-full'>
        <div>
          <Icons
            icon={like ? Heart : LikeIcon}
            alt='Like'
            width='15px'
            content={{ amount: likesAmount, name: 'likes' }}
            textClick={lModalControl}
            iconClick={likeControl}
          />
          {lModal && (
            <LikeModal
              lModal={lModal}
              lModalControl={lModalControl}
              data={data}
              filter={ContentFilter.Like}
            />
          )}
        </div>
        <div className=''>
          <Icons
            icon={Share}
            alt='Like'
            width='15px'
            content={{ amount: data.shareCount, name: 'Share' }}
            textClick={shareModalHandle}
            iconClick={shareModalHandle}
          />
        </div>
        <div className=''>
          <Icons
            icon={Comment}
            alt='Share'
            width='15px'
            content={{ amount: data.commentCount, name: 'Comment' }}
            textClick={cModalControl}
            iconClick={cModalControl}
          />
          {cModal && (
            <CommentsModal
              cModal={cModal}
              cModalControl={cModalControl}
              type={LikeOrCommentType.Journey}
              comments={comments}
              data={{
                data: {
                  doc: {
                    id: data.id,
                    sk: data.sk
                  }
                },
                id: data.id,
                sk: data.sk,
                activityId: data.id,
              }}
            />
          )}
        </div>
        {/* <div className=''>
          <Icons
            icon={Trophy}
            alt='Trophy'
            width='15px'
            content={{ amount: 0, name: 'Badges' }}
            textClick={bModalControl}
          />
          {bModal && (
            <BadegsModal bModal={bModal} bModalControl={bModalControl} />
          )}
        </div> */}
        {/* <div className=''>
          <Icons
            icon={Ideas}
            alt='Ideas'
            width='15px'
            content={{ amount: 0, name: 'Ideas' }}
          />
        </div> */}
        <div className=''>
          <Icons
            icon={Visible}
            alt='views'
            width='15px'
            content={{
              amount: getNumberFormat(data?.viewCount),
              name: 'Views',
            }}
          />
        </div>

        <div className=''>
          <Icons
            activityManageModalHandle={() =>
              data?.isEditable == 1
                ? activityManageModalHandle()
                : showOptionModalHandle(true)
            }
            icon={Option}
            alt='Option'
            width='25px'
          />
        </div>

        <ActivityMangeModal
          successStoryDescription={successStoryDescription}
          activityManageModalHandle={activityManageModalHandle}
          activityManageModal={activityManageModal}
          setTimelineContent={setTimelineContent}
          timeLineContent={timeLineContent}
        />
        {showOptionModal && (
          <FeedOptionModal
            data={data}
            isTimeline={true}
            showOptionModal={showOptionModal}
            showOptionModalHandle={showOptionModalHandle}
          />
        )}
      </div>
      {showImageGallery && (
        <ImageGalleryRenderer
          closeImageGallery={closeImageGallery}
          galleryCurrentIndex={galleryCurrentIndex}
          setGalleryCurrentIndex={setGalleryCurrentIndex}
          photos={sortedGalleryObjects}
        />
      )}
      {shareModal && (
        <ShareModal
          name={data.name}
          photo={data.thumbnail}
          JourneyId={data.id}
          JourneySk={data.sk}
          JourneyCreatedAt={data.createdAt}
          shareModalHandle={shareModalHandle}
          shareModal={shareModal}
        />
      )}
      {toastModal.status && (
        <ToastModal
          text={toastModal.value}
          toastModal={toastModal.status}
          toastModalHandle={toastModalHandle}
        />
      )}
      <div className='w-full h-[158px] absolute top-0'>
        <div
          onClick={() => showParticipantModalHandle(true)}
          className='absolute top-0 bottom-0 left-0 right-0 m-auto w-fit h-fit z-10'
        >
          <UserThumbsWithCounting
            data={data?.participants}
            groupSize={activity?.groupSize}
          />
        </div>
      </div>
      {showParticipantModal && (
        <ParticipantListModal
          jrnyActivity={data}
          showParticipantModal={showParticipantModal}
          showParticipantModalHandle={showParticipantModalHandle}
        />
      )}
    </div>
  );
};

export default TimeLineSlider;
