import { createSearchParams } from 'react-router-dom';
import { generateSlug } from './generate-slug';
import { webBaseURL } from '../environments/proxyUrl';

// import * as CryptoJS from 'crypto-js';
export const getUserNavOption = (user) => {
  let id = `${user.id}_${user.createdAt}_${user.sk}`;

  return {
    pathname: `/user/${generateSlug(user.name)}`,
    search: `?${createSearchParams({
      id,
    })}`,
  };
};

export const generateJourneySharedUrl = (name, JourneyId, JourneySk, JourneyCreatedAt) => {
  return (
    webBaseURL +
    `/journey/${generateSlug(name)}/${generateSlug(
      name
    )}?id=${JourneyId}_${JourneyCreatedAt}_${JourneySk}`
  );
};

export const generateJourneyShareMobileUrl = (name, JourneyId, JourneySk, JourneyCreatedAt) => {
  return (
    webBaseURL + `/activity?id=${JourneyId}_${JourneyCreatedAt}_${JourneySk}`
  );
};

export const parseUserUrlParams = (searchParams) => {
  let id = undefined;

  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param == 'id') {
      id = value;
    }
  }
  if (!id) {
    return undefined;
  }
  const parts = id.split('_');
  if (parts.length < 3) {
    return undefined;
  }

  return {
    id: parts[0],
    createdAt: parts[1],
    sk: parts[2],
  };
};

export const getJourneyNavOption = (journey) => {
  console.log('getJourneyNavOption', journey)
  let id = `${journey.activity ? journey.activity.id : journey.id}_${journey.sk}`;
  if (journey.recordStatus) {
    id += `_${journey.recordStatus}`;
  }

  return {
    pathname: `/journey/${generateSlug(journey?.user?.name)}/${generateSlug(journey.activity ? journey.activity.name : journey.name)}`,
    search: `?${createSearchParams({
      id,
    })}`,
  };
};
export const getTimelieDetailsUrl = (journey) => {
  console.log('getJourneyNavOption', journey)
  let id = `${journey.activity ? journey.activity.id : journey.id}_${journey.sk}`;
  if (journey.recordStatus) {
    id += `_${journey.recordStatus}`;
  }

  return {
    pathname: `/timeline-details/${generateSlug(journey?.user?.name)}/${generateSlug(journey.activity ? journey.activity.name : journey.name)}`,
    search: `?${createSearchParams({
      id,
    })}`,
  };
};

export const parseJourneyUrlParams = (searchParams) => {
  let id = undefined;

  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param == 'id') {
      id = value;
    }
  }
  if (!id) {
    return undefined;
  }
  const parts = id.split('_');
  if (parts.length < 2) {
    return undefined;
  }

  return {
    id: parts[0],
    sk: parts[1],
    recordStatus: parts.length > 2 ? parts[2] : undefined,
  };
};

// const encrypt = (data) => {
//   var ciphertext = CryptoJS.AES.encrypt(
//     JSON.stringify(data),
//     process.env.REACT_APP_CRYPTO_JS_SECRET
//   ).toString();

//   return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(ciphertext));
// };

// const decrypte = (data) => {
//   var bytes = CryptoJS.AES.decrypt(
//     CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8),
//     process.env.REACT_APP_CRYPTO_JS_SECRET
//   );
//   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// };

const input = {
  name: 'tag_name',
  destinationName: 'tag_destinationName',
  location: {
    lat: '32.38',
    lon: '23.23',
  },
  text: 'searchText',
  startDate: 'startDate',
  endDate: 'endDate',
};
export const getTypeHeadServicesNavOption = (
  searchParams,
  name,
  destinationName
) => {
  return {
    pathname: `/services/${destinationName ? generateSlug(destinationName) : 'searchResults'
      }/${name ? generateSlug(name) : 'all'}`,
    search: `?${createSearchParams(searchParams)}`,
  };
};
export const parseTypeHeadServicesUrlParams = (searchParams) => {
  let lat = undefined;
  let lon = undefined;

  let text = undefined;
  let startDate = undefined;
  let endDate = undefined;
  let tags = [];
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param == 'lat') {
      lat = value;
    }
    if (param == 'lon') {
      lon = value;
    }
    if (param == 'text') {
      text = value;
    }
    if (param == 'startDate') {
      startDate = value;
    }
    if (param == 'endDate') {
      endDate = value;
    }
    if (param == 'tag') {
      tags.push(value);
    }
  }
  return {
    lat,
    lon,
    value: text,
    startDate,
    endDate,
    tags,
  };
};

export const generateServiceDetailsUrl = (input) => {
  console.log('Inputs', input);
  return {
    pathname: `/service-details/${generateSlug(input.name)}`,
    search: `?${createSearchParams(input)}`,
  };
};
export const parseServiceDetailsParams = (searchParams) => {
  let sk = undefined;
  let createdAt = undefined;
  let id = undefined;
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param == 'sk') {
      sk = value;
    }
    if (param == 'createdAt') {
      createdAt = value;
    }
    if (param == 'id') {
      id = value;
    }
  }
  return {
    sk,
    id,
    createdAt,
  };
};

export const parseForgotPasswordParams = (searchParams) => {
  let id = undefined;
  console.log('Params', searchParams);
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param == 'id') {
      id = value;
    }
  }
  if (!id) {
    return undefined;
  }
  const parts = id.split('_');
  if (parts.length < 2) {
    return undefined;
  }

  return {
    email: parts[0],
    otp: parts[1],
  };
};
export const emailChangeParams = (searchParams) => {
  let id = undefined;
  console.log('Params', searchParams);
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    if (param == 'id') {
      id = value;
    }
  }
  if (!id) {
    return undefined;
  }
  const parts = id.split('_');
  if (parts.length < 2) {
    return undefined;
  }

  return {
    id: parts[0],
    email: parts[1],
    sk: parts[2],
    createdAt: parts[3],
    otp: parts[4],
  };
};
