import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { tagPaginationOffset } from '../../../res/dimens';
import { baseUrl } from '../../../environments/proxyUrl';
import { parse_SuggestedTask } from './elasticParser';
import { MilestoneSource } from '../../../components/utils/subjects';

export const getSuggestedTasks = createAsyncThunk(
  'task/getSuggestedTasks',
  async ({ data, token, callback }, { rejectWithValue }) => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}/api/activities/v1/stask/`,
        {
          params: data,
          headers: { Authorization: token },
        },
      );

      console.log('getSuggestedTasks', res.results);

      const { tasks } = res.results;

      if (callback) {
        callback(tasks);
      }
      return tasks;
    } catch (err) {
      if (!err.response) {
        throw JSON.stringify(err);
      }
      return rejectWithValue(JSON.stringify(err.response.data));
    }
  },
);

export const getFilters = createAsyncThunk(
  'task/filters',
  async ({ token }, { rejectWithValue }) => {
    console.log('get activities filter2');

    return {
      filters: [
        {
          name: 'all',
          label: 'all',
        },
        {
          name: 'popular',
          label: 'popular',
        },
      ],
    };
  },
);
const taskSlice = createSlice({
  name: 'task',
  initialState: {
    suggestedTasks: [],
    suggestedTasksGrid: [],
    filters: [],
    loading: false,
  },
  reducers: {
    resetSuggestedTask: (state, action) => {
      state.suggestedTasks = [];
    },
    removeSuggestedTask: (state, action) => {
      const { task } = action.payload;
      if (state.suggestedTasks) {
        state.suggestedTasks = state.suggestedTasks.filter(
          t => t.id !== task.id,
        );
      }
      if (state.suggestedTasksGrid) {
        state.suggestedTasksGrid = state.suggestedTasksGrid.filter(
          t => t.id !== task.id,
        );
      }
    },
  },
  extraReducers: {
    [getFilters.pending]: state => {
      state.errors = [];
      state.loading = true;
    },
    [getFilters.fulfilled]: (state, action) => {
      state.errors = [];
      if (action.payload.filters) {
        state.filters = action.payload.filters;
      }
      state.loading = false;
    },
    [getFilters.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    [getSuggestedTasks.pending]: state => {
      state.loading = true;
    },
    [getSuggestedTasks.fulfilled]: (state, action) => {
      state.suggestedTasks = action.payload;
      console.log('state.suggestedTasks', state.suggestedTasks);
      state.loading = false;
    },
    [getSuggestedTasks.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { removeSuggestedTask, resetSuggestedTask } = taskSlice.actions;

export default taskSlice.reducer;

export const taskSelector = state => state.task;
