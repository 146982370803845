import { shareActivity } from '../../../redux/slices/mobile/activitySlice';
import {
  getPhotosUrl,
  constructXLPhotoUrl,
} from '../../../res/commonFunctions';
import { buildUrlToSize } from '../../../utils/build-image-url';
import {
  generateJourneyShareMobileUrl,
  generateJourneySharedUrl,
} from '../../../utils/get-nav-option';
import { PhotoSize } from '../subjects';

export const TimeStampToDate = (timestamp) => {
  let date = new Date(timestamp);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    months[1 + date.getMonth()] +
    ' ' +
    date.getDate() +
    ',' +
    date.getFullYear()
  );
};

export const createGalleryData = (item, type) => {
  console.log('ImageGalleryDataitem', item);
  let imageGalleryData = item
    ? item.map((item) => {
      return {
        caption: item.caption,
        source: {
          uri: getPhotosUrl({
            position: item.position,
            photoReference: type
              ? item.photoReference
              : constructXLPhotoUrl(item.photoReference),
            photoSource: item.photoSource,
          }),
        },
      };
    })
    : [];
  return imageGalleryData;
};

export const googlePhotoGalleryData = (item) => {
  console.log('ImageGalleryDataitem', item);
  let imageGalleryData = item
    ? item.map((item) => {
      return {
        caption: item.caption,
        source: {
          uri: getPhotosUrl(item),
        },
      };
    })
    : [];
  return imageGalleryData;
};

// Creating the firebase dynamic link. Actually this is a conditional like.
// On mobile it will redirect to app if it is installed otherwise google play store.
// On web it will redirect to the website
// This link created when the component load
export const createDynamicLink = async (name, photo, JourneyId, JourneySk, JourneyCreatedAt) => {
  console.log('Gallery URL', name, photo, JourneyId, JourneySk, JourneyCreatedAt);
  const journeyName = name.replace(/ /g, '%20');
  const link = `${buildUrlToSize(
    photo,
    PhotoSize.Medium
  )}&efr=0&st=${journeyName}&apn=com.jrnyfy.app&link=${generateJourneyShareMobileUrl(
    name, JourneyId, JourneySk, JourneyCreatedAt
  )}&ofl=${generateJourneySharedUrl(name, JourneyId, JourneySk, JourneyCreatedAt)}`;

  console.log('Dynamic Link', link);

  return 'https://jrnyfy.page.link/?utm_campaign=banner&si=' + link;
};

export const getSearchParamFromURL = (url, param) => {
  const include = url.includes(param);

  if (!include) return null;

  const params = url.split(/([&,?,=])/);
  const index = params.indexOf(param);
  const value = params[index + 2];
  return value;
};

export const getStatus = (obj, index) => {
  return Object.keys(obj).find((key) => obj[key] === parseInt(index));
};

let numberFormatter = Intl.NumberFormat('en', { notation: 'compact' });
export const getNumberFormat = (num) => {
  console.log('Number Format', numberFormatter.format(num));
  return numberFormatter.format(num);
};

export const getUnseenChats = (id, unseenMessage) => {
  if (unseenMessage?.length > 0) {
    return unseenMessage?.find((p) => p?._id?.chatId === id);
  }
};

export const fileToDataString = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
    reader.onload = () => resolve(reader.result);
  });
};

export function hash(str) {
  let trimedStr = str.trim();
  var hash = 5381,
    i = trimedStr.length;
  while (i) {
    hash = (hash * 33) ^ trimedStr.charCodeAt(--i);
  }
  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}

export const searchDebounce = (callback, delay) => {
  let timmer;
  console.log("calling func")
  return function (...args) {
    console.log("SerachDebounce", args)
    if (timmer) {
      clearTimeout(timmer);
    }
    timmer = setTimeout(() => {
      console.log("searching", args)
      callback(...args)
    }, delay);
  }
}

export const convertSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return { hours, minutes, seconds };
};

