import React, { useEffect, useRef, useState } from 'react'
import playButton from '../../assets/icons/play-button.svg'
import muteIcon from '../../assets/icons/mute-icon.svg'
import unmuteIcon from '../../assets/icons/unmute-icon.svg'
import { buildUrlToSize, buildVideoUrl } from '../../res/commonFunctions'
import { PhotoSize } from '../utils/subjects'

export default function VideoPlayer({
    containerStyle,
    mediaStyle,
    item,
    autoPlay,
    mute,
    loop,
    control,
    callback,
}) {
    const [videoControl, setVideoControl] = useState(autoPlay)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isMute, setIsMute] = useState(true)
    const videoRef = useRef(null);
    useEffect(() => {
        const handleIntersection = (entries) => {
            console.log('VideoIsVidisble', entries)
            entries.forEach(async (entry) => {
                if (entry.isIntersecting) {
                    console.log('VideoIsVidisble')
                    try {
                        await videoRef?.current?.play();
                    } catch (e) {

                    }
                    setIsPlaying(true)
                } else {
                    if (isPlaying) {
                        videoRef?.current?.pause();
                    }
                    setIsPlaying(false)
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5,
        });

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, [])

    return (
        <div onClick={() => {
            videoRef?.current?.pause();
            callback()
        }} className={`${containerStyle} h-full w-full relative`}>
            <video ref={videoRef} key={buildVideoUrl(item)} className={`w-full h-full rounded-[12px] ${mediaStyle}`} controls={videoControl}
                poster={buildUrlToSize(item, PhotoSize.Large)}
                muted={isMute}
                loop
                onWaiting={() => {
                    setIsLoading(true)
                }}
                onPlaying={() => {
                    setIsLoading(false)
                }}
            >
                <source src={buildVideoUrl(item)} />
            </video>
            {isMute ? (<button onClick={(e) => {
                e.stopPropagation()
                setIsMute(false)
            }} className='absolute top-2 right-2'>
                <img src={muteIcon} className='w-[30px] h-[30px]' alt='mute icon' />
            </button>) : (<button onClick={(e) => {
                e.stopPropagation()
                setIsMute(true)
            }} className='absolute top-2 right-2'>
                <img src={unmuteIcon} className='w-[30px] h-[30px]' alt='unmute icon' />
            </button>)}


            {!isPlaying && (<div className='absolute top-0 flex flex-col justify-center items-center w-full h-full bg-[rgba(0,0,0,0.5)] rounded-[12px]'>
                <img src={playButton} className='w-[50px] h-[50px]' alt='play button' />
            </div>)}
            {isLoading && (<div className='absolute top-0 flex flex-col justify-center items-center w-full h-full bg-[rgba(0,0,0,0.5)] rounded-[12px]'>
                <div>
                    <div className='spinner'></div>
                </div>
            </div>)}
        </div>
    )
}
